// extracted by mini-css-extract-plugin
export var contentSlide = "slider-module--content-slide--e195b";
export var contentWrapper = "slider-module--content-wrapper--ba017";
export var featuredSlider = "slider-module--featured-slider--441b4";
export var imageBasedSlider = "slider-module--image-based-slider--9a18e";
export var itemSlide = "slider-module--item-slide--af40d";
export var itemSlider = "slider-module--item-slider--7e7a0";
export var justImageSlide = "slider-module--just-image-slide--6e7c3";
export var mediaSlide = "slider-module--media-slide--3713a";
export var mediaWrapper = "slider-module--media-wrapper--ed3e8";
export var next = "slider-module--next--61fb1";
export var overlayContent = "slider-module--overlay-content--fe9c7";
export var overlayText = "slider-module--overlay-text--d3e6c";
export var preheader = "slider-module--preheader--cc58d";
export var previous = "slider-module--previous--11aa7";
export var slide = "slider-module--slide--bf951";
export var sliderSpillRight = "slider-module--slider-spill-right--6b6ac";
export var subheader = "slider-module--subheader--57055";