import React, { useState, useEffect, useRef } from 'react'
import { Link } from "gatsby"
import Grid, { GridItem } from "./Grid"
import CoverMedia from '../components/CoverMedia'
import MetaInfo from '../components/MetaInfo'
import MaybeLink from './MaybeLink'
// import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { PageTitle } from './PageTitles'


export default function ContentFeed(props) {

    const { feedData, pageTitle } = props;


    function roundDownToMultiple(number, multiple) {
      return number - (number % multiple);
  }   

    let itemsInitial = 12;
    if (props.itemsInitial) itemsInitial = props.itemsInitial;

    let itemsPerLoad = 4;
    if (props.itemsPerLoad) itemsPerLoad = props.itemsPerLoad;

    let totalItemsPerPage = roundDownToMultiple(feedData.length, 4);
    if (feedData.length >= 60) totalItemsPerPage = 60;

    const limitedArray = feedData.slice(0, totalItemsPerPage);

    const allNews = limitedArray

  // State for the list
  const [list, setList] = useState([...allNews.slice(0, itemsInitial)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > itemsPerLoad)

  //Set a ref for the loading div
  const loadRef = useRef()

  // // Handle intersection with load more div
  // const handleObserver = (entities) => {
  //   const target = entities[0]
  //   if (target.isIntersecting) {
  //     setLoadMore(true)
  //   }
  // }

  //Initialize the intersection observer API
  useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }

    // Handle intersection with load more div
    const handleObserver = (entities) => {
      const target = entities[0]
      if (target.isIntersecting) {
        setLoadMore(true)
      }
    }

    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 4)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

    return (
        <div>
          {pageTitle &&
          <PageTitle className={`sm:pb-2 md:pb-4`} as={`h2`}>{pageTitle}</PageTitle>
          }
            <Grid className={`grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 gap-y-3 xl:gap-7 md:py-7`}>
                {list.map(post => {

                  const getPostDate = new Date(post.node.date);
                  const postYear = getPostDate.getFullYear();

                  let maybeUrl = null;
                  if ( post.node.postLinkSettings && post.node.postLinkSettings.postExternalLink ) maybeUrl = post.node.postLinkSettings.postExternalLink;
                  else if ( post.node.presentsGallerySettings && post.node.presentsGallerySettings.eventMediaUrl ) maybeUrl = post.node.presentsGallerySettings.eventMediaUrl;


                    return (
                        <GridItem className={`relative mediaCropSquare`} key={post.node.id}>

                            {post.node.featuredImage ? (
                              <>

                                { post.node.contentType.node.name === "releases" ? (<>
                                    <CoverMedia itemData={post.node} keepHover={true} tooltip={false}>
                                        <MetaInfo metaData={post.node} smallMode={true} />
                                    </CoverMedia>
                                    <Link className={`md:hidden hover:bg-color-text hover:opacity-75 block absolute top-0 right-0 left-0 bottom-0 h-full w-full z-5`} title={post.node.title} to={`/records/${postYear}/${post.node.slug}`}>&nbsp;</Link>
                                </>) : (
                                  <MaybeLink 
                                  link={maybeUrl} 
                                  target={`_blank`} rel={`noopener noreferrer`} title={post.node.title}>
                                    <CoverMedia itemData={post.node} tooltip={true} />
                                </MaybeLink>
                                )
                            
                            }

                            </>
                            ) : ( null )

                            }

                            <h3 className={`hidden`}> {post.node.title} </h3>
                        </GridItem>
                    )
                })}

            </Grid>

            <div className="flex justify-center text-center items-center" ref={loadRef}>
              {hasMore ? <p className={`uppercase font-mono text-base pt-12`}>Loading...</p> : ''}
            </div>
        </div>
    )

}