import *  as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from "./Button"
import { FancyTitle, Subheader } from "./PageTitles";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { HiOutlineArrowRight, HiOutlineArrowLeft } from 'react-icons/hi';
import { previous, next, featuredSlider, imageBasedSlider, slide, mediaSlide, contentSlide, mediaWrapper, contentWrapper, preheader, subheader, justImageSlide, overlayText as overlayTextWrapper, overlayContent} from '../styles/slider.module.scss'
import Banner from "./Banner";
import { LoadingBlock } from "../svg/Loading";


export default function FeaturedSlider(props) {

    const data = useStaticQuery(graphql`
    query FeaturedSliderQuery {
      allWpSnippet(
        limit: 6
        filter: {terms: {nodes: {elemMatch: {slug: {eq: "slider"}}}}, featuredImage: {node: {id: {ne: null}}}}
        sort: {fields: date, order: DESC}
      ) {
            edges {
              node {
                slug
                title
                id
                snippetSettings {
                  mainSnippetHeader
                  snippetSubheader
                  snippetPreheader
                  flipLayout
                  slideCoverImage
                  overlayText {
                    textContent
                    textHeader
                    textPosition
                  }
                  quoteSelection {
                    ... on WpPressKit {
                      id
                      title
                      pressKitSettings {
                        pkArticleLink
                        pkPressQuote
                        pkArticleTitle
                        pkPublicationDate
                        pkSelectPublication {
                          name
                          pressMediaSettings {
                            presslogoWhite {
                              altText
                              localFile {
                                childImageSharp {
                                  gatsbyImageData
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  includePrQuotes
                  snippetLink1 {
                    target
                    title
                    url
                  }
                  snippetLink2 {
                    target
                    title
                    url
                  }
                }
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 96)
                      }
                    }
                  }
                }
                content
              }
            }
          }
    }
  `)

    const snippetData = data.allWpSnippet.edges

    let draggability = props.draggable;

    if ( props.fade === true ) draggability = false;

    let doAutoplay = props.autoplay;

    if (snippetData.length < 2) doAutoplay = false;

    const settings = {
        fade: props.fade,
        dots: false,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: draggability,
        autoplay: doAutoplay,
        autoplaySpeed: props.autoplaySpeed,
        pauseOnHover: false,
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        adaptiveHeight: false,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              adaptiveHeight: true,
              draggable: true,
              autoplay: false,
              arrows: false
            }
          },
          {
            breakpoint: 767,
            settings: {
              adaptiveHeight: true,
              draggable: true,
              autoplay: false,
              arrows: true
            }
          }

        ]
      };

    let sliderRef = React.useRef();

    if (snippetData.length === 1) return (<>

      {snippetData.map(snip => ( <Banner bannerData={snip.node} key={snip.node.id} /> ))}
      </> )
    

    return (
      <div>
        <Slider {...settings} className={[featuredSlider, imageBasedSlider].join(" ")} ref={sliderRef}>
          {snippetData.map(snip => {

            const bannerImageData = getImage(snip.node.featuredImage?.node?.localFile)

            

            // let buttonText = "Go Now";

            let contentOrder = `md:order-1`;
            let mediaOrder = `md:order-2`;

            if (snip.node.snippetSettings.flipLayout) {
              contentOrder = `md:order-2`;
              mediaOrder = `md:order-1`;
            }

            const slideLink1 = snip.node.snippetSettings.snippetLink1;
            const slideLink2 = snip.node.snippetSettings.snippetLink2;

            let btnTitle = "Learn More";

            
          //   const slideLinkCombo = [
          //     slideLink1,
          //     slideLink2
          // ]
      
          // const slideLinkArray = [].concat(...slideLinkCombo);


            // if (snip.node.snippetSettings.snippetButtonText) buttonText = snip.node.snippetSettings.snippetButtonText;

            if (snip.node.snippetSettings.slideCoverImage === true) return (

              <div className={[slide, justImageSlide].join(" ")} key={snip.node.id}>
                <div className={mediaSlide}>
                    <div className={mediaWrapper}>
                      <GatsbyImage image={getImage(bannerImageData)} alt={snip.node.featuredImage.altText || snip.node.title} loading={`eager`} />
                      {/* {snip.node.snippetSettings.slideOverlayText ? <div className={overlayText}><span className={`p-2 md:p-5 lg:-7 xl:max-w-[70%] lg:max-w-[85%]`}>{snip.node.snippetSettings.slideOverlayText}</span></div> : null} */}
                      
                      {(snip.node.snippetSettings?.overlayText?.textHeader || snip.node.snippetSettings?.overlayText?.textContent) ? (
                        <div className={overlayTextWrapper} data-position={snip.node.snippetSettings?.overlayText?.textPosition}>
                          {/* <div className={textBlock}>
                          {snip.node.snippetSettings?.overlayText?.textHeader ? <span dangerouslySetInnerHTML={{__html: snip.node.snippetSettings.overlayText.textHeader}} /> : null}
                          {snip.node.snippetSettings?.overlayText?.textContent ? <span dangerouslySetInnerHTML={{__html: snip.node.snippetSettings.overlayText.textContent}} /> : null}
                          </div> */}
                         <span className={overlayContent}>
                          {snip.node.snippetSettings?.overlayText?.textHeader ? <> <strong dangerouslySetInnerHTML={{__html: snip.node.snippetSettings?.overlayText?.textHeader}} /><br/> </> : null}
                          {snip.node.snippetSettings?.overlayText?.textContent ? <span dangerouslySetInnerHTML={{__html: snip.node.snippetSettings?.overlayText?.textContent}} /> : null}
                         </span>
                        </div>
                      ) : ( null )  }
                    </div>
                  </div>
              </div>
            )
              
            return (
                <div key={snip.node.id} className={slide}>

                    <div className={`flex flex-row items-center md:flex-nowrap flex-wrap md:-m-5 lg:-m-7`}>
                        <div className={`max-w-full flex-full md:p-5 lg:p-7 text-center md:flex-true ${contentOrder} ${contentSlide}`}>
                        {snip.node.snippetSettings.snippetPreheader &&
                          <div className={preheader} dangerouslySetInnerHTML={{__html: snip.node.snippetSettings.snippetPreheader}} />
                        }
                        
                        {snip.node.snippetSettings.mainSnippetHeader &&
                            <FancyTitle>{snip.node.snippetSettings.mainSnippetHeader}</FancyTitle>
                        }

                        {snip.node.snippetSettings.snippetSubheader &&
                          <Subheader className={subheader}>{snip.node.snippetSettings.snippetSubheader}</Subheader>
                        }

                        {snip.node.content &&

                        <div className={contentWrapper} dangerouslySetInnerHTML={{__html: snip.node.content}} />
                        }
                        
                        {snip.node.snippetSettings.includePrQuotes === true &&
                          <PrQuotes prData={snip.node.snippetSettings.quoteSelection} />
                        }

                        {/* {snip.node.snippetSettings.mainSnippetLink &&

                            <div className={`relative z-30 mt-10`}>
                                {snip.node.snippetSettings.snippetUrlType === 'externalLink' ? (
                                    <Button href={snip.node.snippetSettings.mainSnippetLink} type={snip.node.snippetSettings.snippetUrlType}>{buttonText}</Button>
                                ) : (
                                    <Button to={snip.node.snippetSettings.mainSnippetLink} type={snip.node.snippetSettings.snippetUrlType}>{buttonText}</Button>
                                )
                                }
                                
                            </div>
                        } */}

                        {(slideLink1 !== null || slideLink2 !== null) &&
                          <div className={`relative z-30 mt-10 space-x-3`}>
                            {
                            slideLink1 &&

                            <>
                                {slideLink1.target === "_blank" ? (
                                    <Button href={slideLink1.url} type={'external'} title={!slideLink1.title ? btnTitle : slideLink1.title}>{!slideLink1.title ? btnTitle : slideLink1.title}</Button>
                                ) : (
                                    <Button to={slideLink1.url} type={'internal'} title={!slideLink1.title ? btnTitle : slideLink1.title}>{!slideLink1.title ? btnTitle : slideLink1.title}</Button>
                                )}
                            </>

                            }

                            {
                            slideLink2 &&


                            <>
                                {slideLink2.target === "_blank" ? (
                                    <Button variation={`secondary`} href={slideLink2.url} type={`external`} title={!slideLink2.title ? btnTitle : slideLink2.title}>{!slideLink2.title ? btnTitle : slideLink2.title}</Button>
                                ) : (
                                    <Button variation={`secondary`} to={slideLink2.url} type={`internal`} title={!slideLink2.title ? btnTitle : slideLink2.title}>{!slideLink2.title ? btnTitle : slideLink2.title}</Button>
                                )}
                            </>

                    
                            }
                          
                          </div>
                        }
                        </div>
                        {snip.node.featuredImage &&
                            <div className={`md:p-5 lg:p-7 md:flex-[49%] w-full md:max-w-[49%] ${mediaOrder} ${mediaSlide}`}>
                              <div className={mediaWrapper}>
                                <GatsbyImage image={getImage(bannerImageData)} alt={snip.node.featuredImage.altText || snip.node.title} loading={`eager`} />
                              </div>
                            </div>
                        }
                    </div>
                </div>
              )
          })}
        </Slider>
      </div>
    )
}

FeaturedSlider.defaultProps = {
  autoplay: false,
  autoplaySpeed: null,
  fade: true,
  draggable: false
}


export const PreviousArrow = (props) => {
    return (
        <button type="button" className={`${previous} before:hidden`} onClick={props.onClick}>
            <HiOutlineArrowLeft size={28} color={`var(--text-color)`} />
        </button>
    )
}

export const NextArrow = (props) => {
    return (
        <button type="button" className={`${next} before:hidden`} onClick={props.onClick}>
            <HiOutlineArrowRight size={28} color={`var(--text-color)`} />
        </button>
    )
}


export const PrQuotes = props => {

  const { prData } = props;

  const sortedData = prData.sort((a,b) =>  new Date(b.pressKitSettings.pkPublicationDate) - new Date(a.pressKitSettings.pkPublicationDate));

  return (
    <div className={`space-y-5 md:space-y-7 lg:space-y-8 xl:space-y-9`}>
      {sortedData.slice(0,3).map(pr => {

      const pressLogoData = getImage(pr.pressKitSettings.pkSelectPublication.pressMediaSettings.presslogoWhite.localFile)
      const hasImage = pr.pressKitSettings.pkSelectPublication.pressMediaSettings.presslogoWhite
      const articleLink = pr.pressKitSettings.pkArticleLink
      const attrTitle = pr.pressKitSettings.pkArticleTitle || `LTA`;
      // const formatDate = new Date(pr.pressKitSettings.pkPublicationDate).toLocaleString();
      
      const fullQuote = pr.pressKitSettings.pkPressQuote;
      const actualQuote = `“${fullQuote}”`;

      if (!articleLink) return null 

        return (
          <div className={`text-left text-sm lg:px-5 xl:px-7`} id={pr.id} key={pr.id}>
            {pr.pressKitSettings.pkPressQuote &&
             
                <blockquote className={`m-0 p-0 pl-2 indent-[-0.5rem] mb-2 text-[0.75rem] md:text-[0.88rem] leading-[1.22rem] tracking-tighter font-mono`}>
                  {actualQuote}
               </blockquote>
              
            }

            {
              hasImage.localFile &&
              <OutboundLink className={`group pl-2`} href={articleLink} rel={`noopener noreferrer`} target={`_blank`} title={attrTitle}>
                        <GatsbyImage 
                            image={pressLogoData}
                            className={`max-w-[6.5rem] max-h-[1.15rem] md:max-w-[7rem] md:max-h-[1.25rem] lg:max-w-[7.5rem] lg:max-h-[1.5rem] transition-all duration-150 opacity-90 group-hover:opacity-70`}
                            imgStyle={{objectFit: 'contain', height: '100%', objectPosition: 'left top'}}
                            imgClassName={`invert dark:invert-0`}
                            alt={hasImage.altText || prData.title || `LTA`}
                        />
              </OutboundLink>
            }
              
          </div>
        )
      })}
    </div>
  )
}

export const LoadedSlider = ({ ...rest }) => {

  const [isLoaded, setIsLoaded] = React.useState(false);

      React.useEffect(() => {
        setIsLoaded(true);
      },[])

  if (!isLoaded) return <LoadingBlock />

  return <FeaturedSlider {...rest} />
}